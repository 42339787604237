import React from "react";
import getImagePath from "helpers/get-image-path";
import loadable from "@loadable/component";

const _modalType = {
  "customerContactModal": loadable(() => import("components/customer-service-modals/customer-contact")),
  "recoatingServiceModal": loadable(() => import("components/customer-service-modals/recoating-service")),
  "grindingServiceModal": loadable(() => import("components/customer-service-modals/grinding-service")),
  "customerCookwareModal": loadable(() => import("components/customer-service-modals/customer-cookware")),
  "knifeHandleModal": loadable(() => import("components/customer-service-modals/customer-knife")),
  "knifeLayersModal": loadable(() => import("components/customer-service-modals/customer-knife-handle")),
};

const Module = ({ data, children }) => {
  const highlights = data.modal;
  const hightlight = highlights && highlights[0];
  const type = hightlight?._type;
  const emptyModule = (
    <>
      <img src={getImagePath(data.icon)} alt={ data.alt } />
      <p variant="primary">{data.text}</p>
    </>
  );

  if(!hightlight || !type) return emptyModule;

  const RequestedModule = _modalType[type];
  if (RequestedModule) return <RequestedModule  data={data}>{ children }</RequestedModule>;

  return emptyModule;
};

export default Module;
