// extracted by mini-css-extract-plugin
export var productDetailsAccordion = "style-mod--product-details-accordion--3btIE";
export var content = "style-mod--content--1ZVBg";
export var accordionCard = "style-mod--accordion-card--1C9yR";
export var cardHeader = "style-mod--card-header--3NM9E";
export var accordionIcon = "style-mod--accordion-icon--fpgUU";
export var accordionIconOpen = "style-mod--accordion-icon-open--2JUFR";
export var accordionTitle = "style-mod--accordion-title--3p6VV";
export var accordionBody = "style-mod--accordion-body--3f7HM";
export var rating = "style-mod--rating--l_0Yb";
export var clipStar = "style-mod--clip-star--1ZpAl";
export var ratingDesc = "style-mod--rating-desc--1cRN0";