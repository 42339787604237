import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { getReviewSummary } from "data/yotpo-reviews";

const ProductStructuredData = ({ meta }) => {
  if (!meta || Object.keys(meta).length === 0) return <></>;

  const [metaId, setMetaId] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [offerPayload, setOfferPayload] = useState({});

  useEffect(() => {
    const getMetaId = () => meta?.id && atob(meta.id, "base64").replace("gid://shopify/Product/", "");
    if(reviews == null) {
      getReviewSummary(meta.id)
        .then(($reviews) => {
          setReviews($reviews);
          const $metaId = getMetaId();
          setMetaId($metaId);
          let offerObj = { offers: [] };

          //Fill in offer Object 
          meta.variants.map((e) => {
            e.availability ?  e.availability = "http://schema.org/InStock" : e.availability = "http://schema.org/OutOfStock";
            e.url = `https://myolav.com/products/${meta.url}/${e.url}`;
            offerObj.offers.push(e);
          });
          setOfferPayload(offerObj);
        });
    }
  }, []);

  let jsonSchema = {
    "@context": "http://schema.org/",
    "@type": "Product",
    "@id": metaId,
    name: meta.name,
    url: `https://myolav.com/products/${meta.url}`,
    image: meta.image,
    description: meta.description,
    sku: meta.sku,
    brand: { name: "MyOlav" },
    ...offerPayload,
    manufacturer: {
      "@type": "Organization",
      name: "MyOlav",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      "ratingValue": reviews?.average_score,
      "reviewCount": reviews?.total_review,
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jsonSchema)}
      </script>
    </Helmet>
  );
};

export default ProductStructuredData;
