import React from "react";
import BaseLayout from "layouts/base-layout";
import "scss/main.scss";
import "scss/vendor/carousels.scss";
import renderModules from "helpers/render-modules";
import PdpLayout from "components/pdp-layout";
import ProductReviews from "components/product-reviews";
import ProductContext from "services/product-context";
import ProductStructuredData from "components/seo/product";

export default function Product(props) {
  const { product, settings, meta, structured_data } = props.pageContext;

  return (
    <BaseLayout
      data={settings}
      meta={meta || product?.meta}
    >
      <ProductStructuredData meta={structured_data || {}}/>
      <ProductContext.Provider value={product}>
        <PdpLayout />
      </ProductContext.Provider>
      { product?.modules && renderModules(product?.modules) }
      <ProductReviews shopifyId={product?.shopifyId} />
    </BaseLayout>
  );
}
