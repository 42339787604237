import React, {
  useContext, useMemo, useRef, useState
} from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import {  StarFill } from "react-bootstrap-icons";
import ProductContext from "services/product-context";
import VariantCategoryLevel from "../variant-category-level";
import Money from "../money";
import Clock from "assets/images/clock.svg";
import Truck from "assets/images/truck.svg";

import {
  singleProduct, productTitle, productPrice, clipStar,
  advancedProductGroup, cartBtn, tooltipInfo, frContent,
  ratingText, freeReturns, yStars, desktopView, mobileView, productTitlePrice, productPriceCompare
} from "./style.mod.scss";
import KnifeVariants from "../knife-variants";
import VariantList from "../variant-list";

const filterEmptyCategories = (hierarchy) => hierarchy.filter((item) => item.children.find((cat) => cat._type ==="shopifyProductVariant") !== undefined || filterEmptyCategories(item.children).length !== 0);
const getHierarchy = (variantCategories, allCategories, variants) => {
  if (variantCategories.length === 0) return [];

  return variantCategories.map((vc) => {
    const childCategories = getHierarchy(allCategories.filter((cat) => cat.parentCategory?._ref === vc._id), allCategories, variants).map((cat) => ({
      ...cat, parent: vc
    }));

    return {
      ...vc,
      children: childCategories.length === 0 ? variants.filter((variant) => variant.variantCategory?._ref === vc._id): childCategories
    };
  });
};

const getParents = (category, allCategories) => {
  if (!category.parentCategory) return [category];
  const parent = allCategories.find((cat) => cat._id === category.parentCategory._ref);

  return [category, parent, ...getParents(parent, allCategories)].flat();
};

const getSelectedNodes = (variantCategories, currentVariant) => {
  const { variantCategory } = currentVariant;

  if (!variantCategory) return [];

  const matchingCategory = variantCategories.find((vc) => vc._id === variantCategory._ref);

  return getParents(matchingCategory, variantCategories).map((vc) => vc._id);
};

const getVariantCategories = (variantCategories, selected) => variantCategories.map((cat) => ({
  ...cat, selected: selected.indexOf(cat._id) !== -1
}));

const PdpVariations = (props) => {
  const { currentVariant, currentVariant: { sourceData: { compareAtPriceV2, priceV2 } }, onVariantChange, onAddToCart, reviewSummary } = props;

  const { variants, variantCategories, title, content } = useContext(ProductContext);
  const pdpTemplate = content?.pdpTemplate;

  const productInfo = currentVariant?.productInfo;
  const miniIcons = productInfo?.miniIcons;

  const selectedCategoryIds = useMemo(() => getSelectedNodes(variantCategories, currentVariant), [variantCategories, currentVariant]);
  const hierarchy = useMemo(() => {

    const mappedCategories = getVariantCategories(variantCategories, selectedCategoryIds);
    const rootCategories = mappedCategories.filter((cat) => !cat.parentCategory);

    return filterEmptyCategories(getHierarchy(rootCategories, mappedCategories, variants));
  }, [variantCategories, currentVariant]);
  const visibleVariants = variants.filter((variant) => selectedCategoryIds.indexOf(variant.variantCategory?._ref) !== -1);

  const [show, setShow] = useState(false);
  const [ctaDisabled, setDisabled] = useState(false);
  const target = useRef(null);

  const handleAddToCart = async () => {
    setDisabled(true);
    await onAddToCart(currentVariant);
    setDisabled(false);
  };

  const round = (avg) => avg && Math.round(avg);
  const prec = (avg) => avg && Number.parseFloat(avg).toPrecision(2);
  const scrollToReviews = () => document.getElementById("y-product-reviews")?.scrollIntoView({
    behavior: "smooth", block: "start"
  });

  const SummaryReviews = () => {
    if (reviewSummary?.total_review === 0) return <></>;

    return (
      <a className={yStars} onClick={scrollToReviews}>
        {
          reviewSummary?.average_score &&
            Array(round(reviewSummary.average_score)).fill().map(() => (
              <div key={Math.random()} className={clipStar}><StarFill /></div>
            ))
        }
        {
          reviewSummary?.average_score &&
            <span className={ratingText}>{prec(reviewSummary.average_score)} ({reviewSummary.total_review} Bewertungen)</span>
        }
      </a>
    );
  };

  return (
    <div className={singleProduct}>
      <div className={desktopView}>
        <h1 className={productTitle}>{title}</h1>
        <SummaryReviews />
        <p className={productPrice}><Money value={priceV2.amount} currency={priceV2.currencyCode} />
          {compareAtPriceV2.currencyCode !== "NONE" && <span><Money value={compareAtPriceV2.amount} currency={compareAtPriceV2.currencyCode} /></span>}
        </p>
      </div>

      <div className={mobileView}>
        <SummaryReviews />
        <div className={productTitlePrice}>
          <h1 className={productTitle}>{title}</h1>
          <p className={productPrice}><Money value={priceV2.amount} currency={priceV2.currencyCode} />
            {compareAtPriceV2.currencyCode !== "NONE" && <div className={productPriceCompare}><Money value={compareAtPriceV2.amount} currency={compareAtPriceV2.currencyCode} /></div>}
          </p>
        </div>
      </div>

      <div className={advancedProductGroup}>
        {/* <p>Wähle deine Pfanne aus:</p>
        <ul>
          <li className={selected}><span>Beschichtet</span></li>
          <li><a href=""><span>Unbeschichtet</span></a></li>
        </ul> */}
        <VariantCategoryLevel
          categories={hierarchy}
          variants={variants}
          onVariantChange={onVariantChange} />

        {pdpTemplate === "default" && <VariantList
          variants={visibleVariants.map((variant) => ({
            id: variant.id, image: variant.variantIcon?.asset,
          }))}
          onClick={(variant) => onVariantChange(visibleVariants.find((obj) => obj.id === variant.id))}
          isSelected={(variant) => variant.id === currentVariant.id}
        /> }
        {pdpTemplate === "knife" && <KnifeVariants
          variants={visibleVariants}
          currentVariant={currentVariant}
          onVariantChange={onVariantChange} />}
      </div>

      <button className={cartBtn} onClick={handleAddToCart} disabled={ctaDisabled}>In den Warenkorb</button>

      <div className={freeReturns}>
        <div className={frContent}>
          <img src={Truck}></img>
          <p>{ miniIcons?.freeShipping }</p>
        </div>

        <div className={frContent}>
          <img src={Clock}></img>
          <p>
            {
              miniIcons?.shippingETA  && (
                <span
                  className={tooltipInfo}
                  ref={target}
                  onMouseEnter={() => setShow(true)}
                  onMouseOut={() => setShow(false)}
                  style={{
                    color: miniIcons?.colorlist?.value,
                    backgroundCOlor: miniIcons?.colorlist?.value
                  }}
                >
                  { miniIcons?.shippingETA }
                </span>
              )
            }
          </p>
        </div>
        {
          miniIcons?.toolTipMessage && (
            <Overlay target={target.current} show={show} placement="bottom">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  { miniIcons?.toolTipMessage }
                </Tooltip>
              )}
            </Overlay>
          )
        }

      </div>
    </div>
  );
};

export default PdpVariations;
