import React from "react";
import { Card } from "react-bootstrap";
import RichText from "components/rich-text";
import {
  pdpOurPromise, opCard, opList,
  opCardBody, opContent
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";
import Modal from "components/customer-service-modals";

const PdpOurPromise = ({ variant }) => {
  const productInfo = variant?.productInfo;
  const miniDescription = productInfo?.miniDescription;
  const productHighlights = productInfo?.productHighlights;

  return (
    <div className={pdpOurPromise}>
      {
        miniDescription && (
          <div className={opList}>
            <RichText blocks={miniDescription} />
          </div>
        )
      }
      {
        productHighlights && productHighlights.length !== 0 && (
          <Card className={opCard}>
            <Card.Body className={opCardBody}>
              {
                productHighlights.map((_highlt, index) => (
                  <div key={index} className={opContent}>
                    <Modal data={_highlt}>
                      <img src={getImagePath(_highlt.icon)} alt={ _highlt.alt } />
                      <p variant="primary">{ _highlt.text }</p>
                    </Modal>
                  </div>
                ))
              }
            </Card.Body>
          </Card>
        )
      }
    </div>
  );
};

export default PdpOurPromise;
