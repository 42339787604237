import React, { useMemo } from "react";
import { selected, multiple  } from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";

const isVariant = (cat) => cat._type ==="shopifyProductVariant";

const getFirstLeaf = (node) => {
  if (node.children.find(isVariant) !== undefined || node.children.length === 0) return node;

  return getFirstLeaf(node.children[0]);
};


const VariantCategoryLevel = (props) => {
  const { categories, onVariantChange, variants } = props;
  const isLeaf = useMemo(() => categories.find(isVariant) !== undefined , [categories]);
  if (isLeaf) return null;

  const children = categories.map((cat) => cat.children).flat().filter((cat) => cat !== undefined);
  const handleVariantChange = (cat) => {
    const leaf = getFirstLeaf(cat);

    const variant = variants.find((variant) => variant.variantCategory._ref === leaf._id);

    onVariantChange(variant);
  };

  return (
    <>
      <ul className={`${categories.length > 2 ? multiple : null }`}>
        {categories.filter((cat) => cat.selected || !cat.parent || (!cat.selected && cat.parent.selected)).map((cat) =><li className={cat.selected ? selected : ""} key={cat._id}>
          <a onClick={() => handleVariantChange(cat)} role="button">
            {cat.icon && <img srcSet={getImagePath(cat.icon, true)} />}
            <span>{cat.title}</span>
          </a>
        </li>)}
      </ul>
      {!isLeaf && children.length !== 0 && <VariantCategoryLevel
        categories={children}
        onVariantChange={onVariantChange}
        variants={variants}
      />}
    </>
  );
};

export default VariantCategoryLevel;
