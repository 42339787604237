import React from "react";
import {
  box, imgBox, imgBoxSelected, selectedVariant
} from "./style.mod.scss";

import getImagePath from "helpers/get-image-path";


const VariantList = (props) => {
  const { variants, onClick, isSelected, className } = props;
  return <ul className={`${box} ${className}`}>
    {variants.map((variant) => <li className={`${imgBox} ${isSelected(variant) && imgBoxSelected}`} key={variant.id}>
      <a role={onClick ? "button" : "image"} onClick={() => onClick && onClick(variant)}>
        <img className={selectedVariant} src={getImagePath(variant.image) || variant.image} alt={variant.alt} />
      </a>
    </li>)}
  </ul> ;
};

export default VariantList;
