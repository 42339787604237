import React, { useMemo, useState } from "react";
import {
  productImg, mainImg, bigVideo, bigImg, smallVideo,
  mobileSlider, desktopLayout, imageItem, thumbnails,
  swiperLoading, item
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";
import getFilePath from "helpers/get-file-path";
// import ProductContext from "services/product-context";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Pagination]);
const ProductImages = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [imgSrc, setImgSrc] = useState(0);
  // const product = useContext(ProductContext);
  const { currentVariant: { variantImages } } = props;
  const images = useMemo(() => {
    const result = [...variantImages || []];
    (props?.currentVariant?.videos || []).forEach((video) => {
      result.splice(video.position, 0, video);
    });
    setImgSrc(0);
    if(swiper) swiper.slideTo(1, 100);
    return result;
  }, [variantImages]);

  return (
    <div className={productImg}>
      <Swiper
        slidesPerGroup={1}
        slidesPerView="1"
        spaceBetween={0}
        centeredSlides={true}
        pagination={{ clickable: true }}
        className={`${mobileSlider} ${swiper ? "" : swiperLoading}`}
        loop={true}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {
          images.map((image, i) => (
            <SwiperSlide key={i}>
              {
                (image._type==="limitedImage" || image._type==="image") ?
                  <div className={`${imageItem} pointer ${item} item-${i}`} key={image._key}>
                    <img srcSet={getImagePath(image, true)} alt={image.alt} onClick={() => setImgSrc(i)} />
                  </div>
                  : <div className={`${imageItem} pointer ${item} item-${i}`} key={image._key}>
                    <video className={smallVideo} onClick={() => setImgSrc(i)} autoPlay={true} muted={true} playsInline={true} loop>
                      <source src={getFilePath(image.video)} type="video/mp4"/>
                      Sorry, your browser doesnt support embedded videos.
                    </video>
                  </div>
              }
            </SwiperSlide>
          ))
        }
      </Swiper>
      <div className={desktopLayout}>
        {images[imgSrc] && <div className={mainImg}>
          { (images[imgSrc]._type === "limitedImage" || images[imgSrc]._type === "image" ) ?
            <img srcSet={getImagePath(images[imgSrc], true)} alt={images[imgSrc].alt} className={bigImg} />  :
            <video className={bigVideo} src={getFilePath(images[imgSrc].video)} autoPlay={true} muted={true} playsInline={true} loop>
              Sorry, your browser doesnt support embedded videos!!.
            </video>
          }
        </div>}
        <div className={thumbnails}>
          {images.map((image, i) => (
            (image._type==="limitedImage" || image._type==="image") ?
              <div className={`${item} pointer item-${i}`} key={image._key}>
                <img srcSet={getImagePath(image, true)} alt={image.alt} onClick={() => setImgSrc(i)} />
              </div>
              : <div className={`${item} pointer item-${i}`} key={image._key}>
                <video src={getFilePath(image.video)}  className={smallVideo} onClick={() => setImgSrc(i)} autoPlay={true} muted={true} playsInline={true} loop>
                  Sorry, your browser doesnt support embedded videos.
                </video>
              </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProductImages;
