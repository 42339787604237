import React from "react";
import getImagePath from "helpers/get-image-path";
import VariantList from "../variant-list";
import {
  knifeImg, knife, knifeSelected, list, knifeImgWrap, knifeVariants
} from "./style.mod.scss";

const KnifeVariants = (props) => {
  const { variants, currentVariant, onVariantChange } = props;

  return <><ul className={list}>
    {variants.map((variant) => <li
      key={variant.id}
      className={`${knife} ${currentVariant.id === variant.id ? knifeSelected : ""}`}
      onClick={() => onVariantChange(variant)}
    >
      <div className={knifeImgWrap}>
        <img className={knifeImg} src={getImagePath(variant.variantIcon)} alt={variant?.variantIcon?.alt} />
      </div>

    </li>)}
  </ul>
  <VariantList
    variants={[{
      id: currentVariant.id, image: currentVariant.variantIcon
    }]}
    isSelected={() => false}
    className={knifeVariants}
  />
  </>;
};

export default KnifeVariants;
