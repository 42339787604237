import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PdpContact from "../pdp-contact";
import PdpFaq from "../pdp-faq";
import PdpOurPromise from "../pdp-our-promise";
import ProductImages from "../product-images";
import PdpVariations from "../pdp-variations";
import GlobalContext from "services/global-context";
import { container, imagesCol, contentCol } from "./style.mod.scss";
import ProductContext from "services/product-context";
import { getReviewSummary } from "data/yotpo-reviews";
import { navigate, withPrefix } from "gatsby";
import { trackPDPView } from "helpers/analytics";

const PdpLayout =()=> {
  const { addToCart } = useContext(GlobalContext);
  const productContext = useContext(ProductContext);

  if (!productContext) return <></>;

  const { shopifyId, handle, variants, currentVariant: selectedVariant } = productContext;
  const [currentVariant, setCurrentVariant] = useState(selectedVariant || variants[0]);
  const [summary, setSummary] = useState({});

  useEffect(async () => {
    const thisSummary = await getReviewSummary(shopifyId);
    trackPDPView({ 
      variant: currentVariant,  
      product: productContext 
    });
    setSummary(thisSummary);
  }, []);

  const handleVariantChange = (variant) => {
    setCurrentVariant(variant);
    const windowDefined = typeof window !== "undefined";
    trackPDPView({ 
      variant: currentVariant,  
      product: productContext 
    });

    if(variant.handle) {
      windowDefined ?
        window.history.pushState("", "", `/products/${handle}/${variant.handle.current}`) :
        navigate(withPrefix(`/products/${handle}/${variant.handle.current}`), { replace: true });
    }
  };

  return (
    <div>
      <Container className={container}>
        <Row>
          <Col lg={8} className={imagesCol}>
            <ProductImages currentVariant={currentVariant} />
          </Col>
          <Col lg={4} className={contentCol}>
            <PdpVariations
              currentVariant={currentVariant}
              onVariantChange={handleVariantChange}
              onAddToCart={addToCart}
              reviewSummary={summary}
            />
            <PdpOurPromise variant={currentVariant} />
            <PdpFaq variant={currentVariant} reviewSummary={summary} />
            <PdpContact />
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default PdpLayout;
