// extracted by mini-css-extract-plugin
export var singleProduct = "style-mod--single-product--2I3tN";
export var productTitle = "style-mod--product-title--1DYqg";
export var ratingText = "style-mod--rating-text--2uxZi";
export var clipStar = "style-mod--clip-star--2Y3ph";
export var productPrice = "style-mod--product-price--3BvuF";
export var advancedProductGroup = "style-mod--advanced-product-group--3arKT";
export var selected = "style-mod--selected--Hm-0K";
export var multiple = "style-mod--multiple--1edGj";
export var box = "style-mod--box--FyvMK";
export var imgBox = "style-mod--img-box--31xYz";
export var freeReturns = "style-mod--free-returns--3VMFl";
export var cartBtn = "style-mod--cart-btn--2J3wt";
export var tooltipInfo = "style-mod--tooltip-info--1Kxx7";
export var container = "style-mod--container--1MEIl";
export var imagesCol = "style-mod--images-col--17srp";
export var contentCol = "style-mod--content-col--3xN1S";