// extracted by mini-css-extract-plugin
export var productImg = "style-mod--product-img--12i3z";
export var mobileSlider = "style-mod--mobile-slider--3X1eR";
export var swiperLoading = "style-mod--swiper-loading--1s1dZ";
export var imageItem = "style-mod--image-item--31_n2";
export var thumbnails = "style-mod--thumbnails--AYv9V";
export var item = "style-mod--item--1JIPO";
export var desktopLayout = "style-mod--desktop-layout--2hlR8";
export var mainImg = "style-mod--main-img--2Pm-F";
export var bigVideo = "style-mod--big-video--3hpmP";
export var bigImg = "style-mod--big-img--1tsK2";
export var smallVideo = "style-mod--small-video--3spP-";