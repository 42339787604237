import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import getLoc from "locales";
import GlobalContext from "services/global-context";
import {
  pdpContactCard, pcCard, pcTitle,
  pcDescription, pcBtn, btnContainer
} from "./style.mod.scss";

const PdpContact = () => {
  const { locale } = useContext(GlobalContext);
  const conLoc = getLoc()[locale]?.products?.contact;

  return (
    <div className={pdpContactCard}>
      <Card className={pcCard}>
        <Card.Body>
          <img src={conLoc?.imgUrl} />
          <p className={pcTitle}>{ conLoc?.title }</p>
          <p className={pcDescription}>{ conLoc?.description }</p>
          <div className={btnContainer}>
            <button className={pcBtn} >
              <a href={`tel:${ conLoc?.tel }`}>
                { conLoc?.callBtn }
              </a>
            </button>
            <button className={pcBtn}>
              <a  href={`mailto:${ conLoc?.email}`}>
                { conLoc?.emailBtn }
              </a>
            </button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PdpContact;
