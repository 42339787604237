import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  productDetailsAccordion, content,
  accordionCard, cardHeader, accordionTitle,
  accordionIcon, accordionBody, rating, clipStar,
  ratingDesc, accordionIconOpen
} from "./style.mod.scss";
import RichText from "components/rich-text";
import {  ChevronDown, StarFill } from "react-bootstrap-icons";

const PdpFaq = ({ reviewSummary, variant }) => {
  const [open, setOpen] = useState({});

  const round = (avg) => avg && Math.round(avg);
  const scrollToReviews = () => document.getElementById("y-product-reviews")?.scrollIntoView({
    behavior: "smooth", block: "start"
  });

  const productInfo = variant?.productInfo;
  const productTabs = productInfo?.productTabs;

  const SummaryReviews = () => {
    if (reviewSummary?.total_review === 0) return <></>;

    return (
      <div onClick={scrollToReviews} className={rating}>
        <div>
          {
            reviewSummary?.average_score &&
              Array(round(reviewSummary.average_score)).fill().map(() => (
                <div key={Math.random()} className={clipStar}><StarFill /></div>
              ))
          }
        </div>
        {
          reviewSummary?.total_review &&
            <span className={ratingDesc}>({reviewSummary?.total_review} Bewertungen)</span>
        }
        <a>lesen</a>
      </div>
    );
  };

  return (
    <div className={productDetailsAccordion}>
      <div className={content}>
        <Accordion className="pointer">
          {
            productTabs?.map((_tab, index) => (
              <Card key={index} className={accordionCard}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={_tab._key}
                  className={cardHeader}
                  onClick={() => setOpen(open === _tab._key ? null : _tab._key)}
                >
                  <p className={accordionTitle}>{_tab.title}</p>
                  <span className={`${accordionIcon} ${_tab._key !== open ? "" : accordionIconOpen}`}>
                    <ChevronDown/>
                  </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={_tab._key}>
                  <Card.Body className={accordionBody}>
                    <RichText blocks={_tab.content} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))
          }
        </Accordion>
      </div>
      <SummaryReviews />
    </div>
  );
};

export default PdpFaq;
