// extracted by mini-css-extract-plugin
export var singleProduct = "style-mod--single-product--2_TJM";
export var productTitle = "style-mod--product-title--1pXSj";
export var frContent = "style-mod--fr-content--12OAg";
export var yStars = "style-mod--y-stars--2PrfX";
export var desktopView = "style-mod--desktop-view--3l9Go";
export var mobileView = "style-mod--mobile-view--Qj_DO";
export var productTitlePrice = "style-mod--product-title-price--1vtc7";
export var productPrice = "style-mod--product-price--7EbmA";
export var productPriceCompare = "style-mod--product-price-compare--3MCCr";
export var ratingText = "style-mod--rating-text--1cjjG";
export var clipStar = "style-mod--clip-star--3Am-J";
export var advancedProductGroup = "style-mod--advanced-product-group--2IeHP";
export var selected = "style-mod--selected--1Vc8J";
export var freeReturns = "style-mod--free-returns--1yU7J";
export var cartBtn = "style-mod--cart-btn--2YVbZ";
export var tooltipInfo = "style-mod--tooltip-info--27rXH";